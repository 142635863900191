

.bottom_action_container {
    position: fixed;
    z-index: 8;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    vertical-align: middle;
    text-align: center;
    padding: 0.5em 0 0.5em 0;
}

.bottom_action_button {
    display: inline-block;
    border-radius: 6px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1em;
    padding: 0.5em 4em 0.5em 4em;
    vertical-align: middle;
    text-align: center;
	font-size: 100%;
    transition: none;

    color: #979797;
    background-color: #FFF;  /* Button Hintergrund nicht transparent, damit er Inhalte darunter überdeckt und lesbar ist */
    outline: solid 1px #979797; /* #DDE1E8; */
}

.bottom_action_button.primary_action {
    color: #FBFBFB;
    background-color: #32A0E6;
    outline: none;
}

.bottom_action_button.primary_action:active {
    background-color: var(--form-background-color) !important;
    -webkit-transition: background-color 500ms ease;
    -moz-transition: background-color 500ms ease;
    -o-transition: background-color 500ms ease;
    transition: background-color 500ms ease;
}

.bottom_action_button.selection {
    color: #FBFBFB;
    background-color: #D2000D;
    outline: none;
}

.bottom_action_button.selection:active {
    background-color: #ff0000;
    -webkit-transition: background-color 500ms ease;
    -moz-transition: background-color 500ms ease;
    -o-transition: background-color 500ms ease;
    transition: background-color 500ms ease;
}

.bottom_action_button {
    display: inline-block;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1em;
    padding: 0.5em 4em 0.5em 4em;
    vertical-align: middle;
    text-align: center;
    font-size: 100%;
    transition: none;

    color: #979797;
    background-color: #FFF;  /* Button Hintergrund nicht transparent, damit er Inhalte darunter überdeckt und lesbar ist */
    outline: solid 1px #979797; /* #DDE1E8; */
}
.bottom_action_button.selectionGreen {
    color: #FBFBFB;
    background-color: green;
    outline: none;
}
