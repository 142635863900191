.svgButton {
  float: left;
}

#currentAttendances {
  /*float: left;*/

  .headlabel {
    font-size: 12px;
    padding: 0;
    color: #979581;
    margin: 0;
  }

  .label {
    font-size: 11px;
    padding: 0;
    color: #979581;
    margin: 0;
  }

  .value {
    font-size: 14px;
    color: #3F3C14;
    padding: 0;
    margin: 0;
  }

  .attendanceBox {
    /*float: left;*/
    /*padding-right: 14px;*/
  }

  .horizontalPartBox {
    min-width: 100px;
  }

}

.attendanceClockBTNBox {
  padding-top: 10px;
}
