.floating_action_button:active {
        background-color: #67bcec3b;
        -webkit-transition: background-color 500ms ease;
        -moz-transition: background-color 500ms ease;
        -o-transition: background-color 500ms ease;
        transition: background-color 500ms ease;
    }

    .floating_action_button {
        position: fixed;
        z-index: 10;
        display: inline-block;
        border-radius: 50%;
        right: 0.5em;
        padding: 16px 16px 16px 16px;
        vertical-align: middle;
        line-height: 0;
        text-align: center;
        font-size: 150%;
        color: var(--FloatingButton-Color);
        background-color: var(--FloatingButton-Background);
        transition: 0;
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.2);
    }
