  .blockGuiLayer {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #67BCEC;
    opacity: 0.3;
  }

  .blockGuiHidden {
    opacity: 0;
  }

  .blockGuiWhileSaving {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;


    .blockGuiText {
      position: relative;
      float: left;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #535353
    }
  }

  .timerecordinglist {

    .noAttendanceLabel {
      margin: 0;
      color: #9BA8B9;
      font-size: 80%;
      text-align: center;
      padding-top: 16px;
    }

    .selectorFlexBox {
      background-color: var(--mainBackgroundColor);
    }
  }

  .timeRecordingOverview.listview {
    margin: 0px;
    background: none;
  }

  .timeRecordingOverview {


    .listrow {

      div {
        z-index: 1;
      }

      height: 47px;
      border-top: var(--border-color) 1px solid;
      background-color: var(--PrimaryElement-Color);

      .rowBackground {
        position: absolute;
        z-index: -99;
        /*TODO:Jens: -1px verhindert scrollbalken, analysieren warum der auftritt*/
        width: calc(100% - 1px);
      }

      .durationText {
        float: right;
        margin-right: 5px;
        font-size: 14px;
        margin-top: 12px;
      }

      .taskDescription {
        max-width: calc(100vw - 48px);
        overflow: hidden;
      }

      .descriptionText {
        margin: 0px;
        margin-top: 12px;
        margin-left: 5px;
        font-size: 14px;
        font-weight: 500;
        color: var(--page-color);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .listrow:last-child {
      border-bottom: var(--border-color) 1px solid;
    }

    .listrow.isPause {
      height: 47px;
    }


    .isSyncOnlyChangedInApp {
      .durationText {
        color: #BC7F1C !important;
      }
    }

    .listrow.isDummy {
      height: 20px;

      .rowBackground {
        background: repeating-linear-gradient(
                145deg,
                transparent,
                transparent 8px,
                var(--border-color) 8px,
                var(--border-color) 18px
        );
      }

      .durationText {
        float: right;
        margin-right: 5px;
        font-size: 14px;
        margin-top: 2px;
      }
    }

    .listrow.isDummy.isAppointmentDummy {
      height: 47px;

      .durationText {
        margin-top: 12px;
      }
    }

    .listrow.attendanceSummary {
      height: 47px;
      background-color: var(--PrimaryElement-Color);

      .backgroundRowWrapper {
        padding-top: 16px;
      }
    }


    .nonAttributed {
      .bg-rect {
        x: 0 !important;
        width: 100vw !important;
      }
    }

    .row_pspPath {
      font-size: 12px;
      margin: 0px;
      margin-left: 5px;
      margin-top: 0px;
      white-space: nowrap;
      width: calc(100vw - 10px);
      overflow: hidden;
      text-overflow: ellipsis;
      position: absolute;
      margin-right: 5px;
      color: var(--page-secondary-color);
      line-height: var(--timeRecordingPSPPath-line-height);
    }

  }

  .selectorHeadDate {
    text-align: center;
    font-size: 22px;
    padding-top: 7px;
    padding-bottom: 10px;
    color: var(--timeRecording_base_color);
    @media (min-width: 450px) {
        font-size: 26px;
    }
  }

    .selectorArrowRight {
      background: url("../images/buttons/chevron-right-blue@1x.svg");
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center 13px;
      cursor: pointer;
    }

    .selectorArrowLeft {
      background: url("../images/buttons/chevron-left-blue@1x.svg");
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center 13px;
      cursor: pointer;
    }

    .nobookings {
      width: 100%;
      height: 100%;
      text-align: center;
      vertical-align: middle;
      color: var(--page-secondary-color);
      padding-top: 15%;
      font-size: 23px;
    }

    .underlayingEffortTarget.listRowField {
      padding: 0;
      font-size: 0.7em;
      color: var(--page-secondary-color);


      table.layout td.icon {
        min-width: 2.8em;
      }
    }


    .timeRecordTimeAttributes {
      margin-top: 10px;
      margin-bottom: 10px;

      td.icon img {
        margin-top: 19px;
      }

      tr > td:nth-child(2),
      tr > td:nth-child(3) {
        width: 77px;
        padding-right: 5px;
      }
    }


    tr > td.rightColumn {
      width: 77px;
      padding-right: 5px;
    }

    .planBookedRemainSumsAttributes {
      width: auto !important;
    }

    .text_hyphen {
      padding-top: 17px;
      padding-left: 10px;
      color: var(--subMenuBackground);
    }

    .hideNavigationToForecastButton {
      display: none !important;
    }
