/**
Variablen und gesonderte Styles für den Darkmode der App
 */
@media (prefers-color-scheme: dark) {
  :root {
    color-scheme: dark;

    // Allgemeine Farben und Boardelemente
    --greyBaseColor: #1d1d1d;
    --mainBackgroundColor: #121212;

    --PrimaryElement-Color: #1d1d1d;

    --subMenuBackground: #323232;
    --subMenu-Border: var(--mainBackgroundColor);
    --selectedOptionColor: #ffffff;
    --contactBoxColor: #639558;

    --Calendar-TodayBackground: var(--form-placeholder-color-zero);

    --page-color: #E0E0E0;
    --page-secondary-color: #A0A0A0;

    --form-placeholder-color: #8c8c8c;
    --form-placeholder-color-zero: #666666;

    // ListView:
    --clickActiv-Color: #67bcec3b;
    --defaultListView-Background: var(--PrimaryElement-Color);
    --ListView-Title: var(--page-color);
    --ListView-Border: 1px solid #212425;
    --ListView-FormField: var(--page-color);
    --ListView-TableData: var(--page-color);
    --ListView-FieldFont: var(--page-color);
    // Navigation:
    --NavigationHeader-Color: #093552;
    --FooterToolbar-Background: #222222;
    --FooterToolbar-Border: #222222;

    // Zeiterfassung und Restaufwandsschätzung
    --timeRecording_base_color: #3FA6E7;
    --TimeRecording_UnbookedTimeColor: #444444;
    //Spesenerfassung
    --allowance_base_color: #BC6E90;
    --travelSection-Background: #09355270;
    /// Buchungsfarben

    --booking-TextColor: var(--page-secondary-color);
    --pause-TextColor: var(--greyBaseColor);
    --booking-BackgroundColor: var(--NavigationHeader-Color);
    --pause-BackgroundColor: var(--PrimaryElement-Color);
    --dummy-BackgroundColor: #6c2c72;
    --dummy-TextColor: var(--page-color);
    --placeholderBooking-BackgroundColor: var(--page-secondary-color);
    --placeholderBooking-TextColor: var(--page-color);
    --synchedBooking-BackgroundColor: #7e7249;
    --synchedBooking-TextColor: #BC7F1C;

    // Spesenerfassung

    // Login und Formularfelder
    --form-color: #E0E0E0;
    --form-background-color: #262626;

    --form-label-color: #A0A0A0;
    --form-border-color: #6A6A6A;

    --loginFieldColor: var(--mainBackgroundColor);
    --loginTitelColor: var(--page-color);
    //ProgressLayer
    --ProgressLayer-Font: var(--page-color);
    --ProgressLayer-Background: #212425;
    //Floating Action Button
    --FloatingButton-Background: #156FA8FF;
    --FloatingButton-Color: var(--mainBackgroundColor);
    // Kontakterfassung

    /** Separator-Farbe */
    --border-color: #FFFFFF20;

  }

  // Allgemeine Farben und Boardelemente
  .toolbar_container {
    img {
      filter: brightness(0) invert(1) brightness(0.8);
    }
  }

  .contactBox {
    font-weight: 500;
  }

  .boardIcon {
    filter: brightness(0) invert(1) brightness(0.8);
  }

  body {

    // Check Icon
    input[type=checkbox]:checked {
      background-image: url(../images/buttons/checkbox-checked-dark@1x.svg);
    }

    input.bcsDateField,
    input[type=time],
    select {
      background-image: url(../images/buttons/dropdown1x-dark.svg) !important;

    }
  }

  .forecastBox {
    font-weight: 500;
  }

  .calendarControll table .dayOfMonthCell.today {
    color: var(--page-color);
  }
}