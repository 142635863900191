

/* 
 * Tabbed Bar mit jeweils einem unterstrichenen aktiven Tab.
 * Balken wechselt animiert zwischen aktiven Tabs.
 * Siehe TabbedViewBar
 */
.tabbed_view_switch {

	width: 100%;
	font-size: 100%;
	color: #32A0E6;
	background-color: var(--FooterToolbar-Background);
    border-bottom: solid 1px  var(--FooterToolbar-Background);

    /* Jeder Tab zeigt den Titel seines Inhalts */
	.tab_switch {
		padding: 0.5em 1em;
		text-align: center;
        white-space: nowrap;
	}

    /* Der Titel des aktive Tab ist hervorgehoben */
	.tab_switch.active {
		font-weight: bold;
		transition: all .2s ease;
    }
    
    /* Blauer Balken unter aktiven Tab, wechselt animiert zwischen linkem und rechtem aktivem Tab */
    .activeBar {
        position: absolute;
		z-index: 10;
        bottom: 0;
        width: 50%;
		height: 3px;
		background: #32A0E6;
		display: block;
		left: 0;
		transition: all .2s ease;
    }

    /* Blauer Balken positioniert unter linkem aktiven Tab */
    .tab_switch0.active ~ .activeBar {
		left: 0;
    }

    /* Blauer Balken positioniert unter rechtem aktiven Tab */
    .tab_switch1.active ~ .activeBar {
        left: 50%;
    } 
}

/* Optional: TabbedBar fixiert am oberen Rand */
.tabbed_view_switch_fixed {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 2;
}
