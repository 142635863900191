  #header_toolbar {
    position: fixed;
    top: 0;
    width: 100%;
    height: 40px;
    background-color: var(--NavigationHeader-Color);
    z-index: 2;
    transform: translate(0, 0);
  }

  #footer_toolbar {
    padding-bottom: var(--bottom-offset);
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: var(--FooterToolbar-Background);
    z-index: 2;
    transform: translate(0, 0);

    /* Trenner nach oben zum Inhalt */
    border-top: 1px solid var(--FooterToolbar-Border);

    .toollink {
      width: 60px;
      height: 60px;
      position: relative;

      span {
        font-size: var(--footerIcon-font-size);
        position: absolute;
        bottom: 4px;
        left: 0px;
        width: 60px;
        color: #999999;
      }

      img {
        filter: none; // damit die Footerelemente auch im Darkmode eingefärbt werden
      }
    }

    .toollink.active {
      span {
        color: #39A1E4;
      }
    }
  }

  .toolbar {

    height: 60px;

    .toolbar_container {
      height: 100%;
      padding-right: 0.2em; /* Abstand für letzten ToolLink rechts außen, z.B. "Alle" */
    }

    .toolLinkDiv {
      margin-left: 10px;
    }
    .title {
      color: var(--NavigationTitle-Color);
      font-weight: bold;
      /*margin-top: 9px;*/
      white-space: nowrap;
      margin: 0;
      padding: 0;
    }

    svg.iconBadge {
      position: absolute;
      top: 1px;
      left: 0px;
    }

    .minilabel {
      position: absolute;
      right: 0;
      bottom: 0;
      padding: 3px;
      font-size: 0.75em;
      color: var(--page-secondary-color);
      max-width: 4em;
      text-align: right;
    }
  }

  /* Für iPhones mit runden Ecken (iPhone X / XR / XS / XS Max): Platz rechts neben Versionsnummer wegen runder Ecken */
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3), only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), only screen and(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
    .toolbar {
      .minilabel {
        padding-right: 1em;
      }
    }
  }

  .toollink {
    display: inline-block;
    text-align: center;
    white-space: nowrap;

    img {
      margin-top: 3px;
      vertical-align: middle;
    }

    span {
      font-size: 120%;
      color: black;
      width: 10em;
      vertical-align: middle;
    }
  }

  .toollink:active {
    background-color: var(--click_activ_color);
  }
