.defaultListView input.formbutton[name="saveAnnotationBtn"] {
  display: none;
}

.defaultListView input.formbutton[name="saveAnnotationBtn"].showButton {
  display: block;
  font-size: 15px;
  font-family: Roboto, "Segoe UI", "Frutiger", Helvetica, "Helvetica Neue", Tahoma, Verdana, Arial, sans-serif;
  color: white;
  min-width: 90px;
  background-repeat: repeat-x;
  margin: 0 5px 0 15px;
  padding: 2px 10px 1px 10px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px 4px 4px 4px;
  display: inline-block;
  background-color: #32A0E6 !important;
  text-align: center;
  float: none !important;
  cursor: default;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  line-height: 23px;
}



.forecastBox {
  font-weight: 500;
  color: var(--timeRecording_base_color);
  font-size: 1.1em;
  justify-content: center;
  height: 100%;
}

.disabled {
  visibility: hidden;
}
