

.travelBoxLayout {
    border-left: 0.5em solid var(--mainBackgroundColor);
    border-right: 0.5em solid var(--mainBackgroundColor);
    border-top: 1em solid var(--mainBackgroundColor);
    border-bottom: 1em solid var(--mainBackgroundColor);
    padding-top: 0 !important;
}

/** Dienstreise-Reiseabschnitt */
.travelsection {
    /*
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    */

	.travelsection_header {
      background-color: var(--travelSection-Background);
        /*
        border-top: 1px solid #F5F9FF;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        */

		padding: 0.5em;

		/** Nummer des Reiseabschnitts */
		.travelsection_number {
			margin-right: 0.5em;
            background-color: #BACAE0;
			display: inline-block;
			border-radius: 50%;   
			padding: 4px 9px 5px 9px;
			vertical-align: middle;
			line-height: 1;
			text-align: center;
			font-size: 120%;
			color: var(--defaultListView-Background);
		}

		/** Titelzeile eines Reiseabschnittes */
		.travelsection_title {
			font-size: 100%;
			font-weight: bold;
		}
    }

    .action {
        border-top: 1px solid var(--mainBackgroundColor);
        /*
        border-top-right-radius: 20px;
        */
    }
}

/* Formular mit allen Reisespesen eines Resiseabschnitts */
.travelsection_separator {
    height: 1em;

background: var(--mainBackgroundColor);
    border-color: var(--mainBackgroundColor);
    border-width: 2px 0 0 0;
    border-style: solid;
}

.create_travelsection {

    padding: 0.5em;

    /** Nummer des neuen Reiseabschnitts */
    .create_travelsection_number {
        margin-right: 0.5em;

        display: inline-block;
        border-radius: 50%;   
        border: 1px solid currentColor;
        padding: 4px 9px 5px 9px;
        vertical-align: middle;
        line-height: 1;
        text-align: center;
        font-size: 120%;
        color: #BACAE0;
        /* background-color: #BACAE0; */
    }

    /** Label des Links zum Erstellen eines neuen Reiseabschnittes */
    .create_travelsection_label {
        font-size: 120%;
        color: #32A0E6;
    }
}

.create_voucher {
    font-size: 100%;
    color: #32A0E6;

    padding: 1em 0 1em 0.25em !important;
}

.listview_fieldset + .create_voucher {
   border-top: 1px solid #DDE3ED;
 }

.travelAllowanceForm {

    margin-top: 0.25em;
    margin-bottom: 0;

    .listview_fieldset:last-child {
        margin-bottom: 0.5em;
        padding-bottom: 0;
        border-bottom: none;
    }

    .listrow:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
    }

    .compoundFieldMinWidth {
        
        table.layout {
            width: auto !important;
        }
    }

    .compoundFieldAlignLeft {

        table.layout {
            td.content {
                text-align: left;
            }
        }
    }
}
