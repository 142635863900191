    /** Standard-ListView */
    .defaultListView {

        box-sizing: border-box;
        font-size: 110%;
        background-color: var(--defaultListView-Background);
        padding-top: 10px;

        .defaultListView {
            padding-top: 0;
        }

        /** Titel über gesamtem ListView */

        .title {
            margin-left: 0.6em;
            text-transform: uppercase;
            font-variant: small-caps;
            color: var(--ListView-Title);
            font-size: 90%;
        }

        /** ListRow */

        .listrow {

            margin-top: 0;
            overflow: hidden;

            /** Aktionsbereich (rechts, z.B. Unterseite öffnen oder Zeile löschen) */

            .action {

                min-width: 1.8em;
                max-width: 1.8em;
                width: 1.8em;

                opacity: 1;

                span {
                    font-size: 150%;
                    color: #727272;
                }
            }

            .deOut {
                opacity: 0;

                transition: opacity 3s ease-in;
                -webkit-transition: opacity 3s ease-in;
                -moz-transition: opacity 3s ease-in;
                -ms-transition: opacity 3s ease-in;
                -o-transition: opacity 3s ease-in;
            }

            /** Aktionsbereich mit Lösch-Icon */

            .deletable {
                /* background-color: #F8F9FC; */
            }

            /** Aktionsbereich mit zum Löschen ausgewähltem Lösch-Icon */

            .deletable_selected {
                background-color: #D0021B;
            }
        }

        .listrow:last-child {
            border-bottom: var(--ListView-Border);
        }

        .listrow:active {
            background-color: var(--clickActiv-Color);
        }

        .listview_fieldset:not(:first-child) {
            border-top: var(--ListView-Border);
            padding-top: 10px;
            margin-top: 10px;
        }

        .listview_fieldset:last-child {
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-bottom: var(--ListView-Border);
        }

        /** FieldSet */

        .listview_fieldset {

            box-sizing: border-box;
            /*margin: 0.5em;*/

            .fieldset_title {
                margin-left: 0.6em;
                text-transform: uppercase;
                font-variant: small-caps;
                color: var(--form-label-color);
                font-size: 90%;
            }

            .listrow:last-child {
                border: none;
            }
        }

        table.listRowFieldTable {

            width: 100%;

            td {
                padding: 0;
                vertical-align: top;
            }

            td.icon {
                min-width: 1.8em;
                width: 1.8em;

                img {
                    vertical-align: text-bottom;
                }
            }

            .listRowField {
                padding: 0;
            }

            .title {
                margin-left: 2px;
                white-space: nowrap;
            }
        }

        table.listRowCompoundField {

            width: 100%;

            tr.mainContent {

                td {
                    vertical-align: top;
                    font-size: 100% !important;
                    white-space: nowrap;

                    padding: 0;

                    div.listRowField {
                        padding: 0;
                    }

                    div.formfieldDisplayMode {
                        font-size: 100% !important;
                        color: var(--ListView-FormField);
                        display: inline-block;
                    }
                }

                td.icon {
                    min-width: 1.8em;
                    max-width: 1.8em;
                    width: 1.8em;

                    img {
                        vertical-align: text-bottom;
                    }
                }

                td.leftContent {
                    overflow-x: hidden;
                    vertical-align: middle;
                    color: var(--ListView-TableData);
                }

                td.rightContent {
                    overflow-x: hidden;
                    text-align: right;
                    padding-right: 0.5em;
                    vertical-align: middle;
                    color: var(--ListView-TableData);
                }
            }

            tr.superContent, tr.subContent {

                td {
                    vertical-align: top;
                    white-space: nowrap;

                    font-size: 90%;
                    color: var(--page-secondary-color);
                    padding: 0;

                    div.listRowField {
                        padding: 0;
                    }

                    div.formfieldDisplayMode {
                        font-size: 100%;
                        color: var(--page-secondary-color);
                    }

                    input.displayMode.formfield {
                        font-size: 100%;
                        color: var(--page-secondary-color);
                    }
                }

                td.icon {
                    height: 20px !important;
                }

                td.leftContent {
                    overflow-x: hidden;
                    vertical-align: middle;
                }

                td.rightContent {
                    padding-right: 0.5em;
                    text-align: right;
                    overflow-x: hidden;
                    vertical-align: middle;
                }
            }
        }

        .listRowField {

            box-sizing: border-box;
            width: 100%;
            padding: 0.3em 0;

            .title {
                height: 1.2em;
                font-size: 90%;
            }

            table.layout {

                width: 100%;
                table-layout: fixed;

                td {
                    padding: 0;
                }

                td.icon {
                    min-width: 1.8em;
                    max-width: 1.8em;
                    width: 1.8em;
                    height: 33px;

                    img {
                        vertical-align: text-bottom;
                    }
                }

                td.content {
                    vertical-align: middle;
                    padding-bottom: 3px;
                    font-size: 17px;
                }

                // Undefinierte Pflichfelder markieren
                td.content.required {
                    position: relative;

                }

                td.content.required:not(.notRequired):after {
                    content: "*";
                    position: absolute;
                    right: -10px;
                    color: red;
                    top: 7px;
                }

                td.feedback {
                    min-width: 0.8em; /* 1.8em; */
                    opacity: 1;

                    img {
                        width: 0.8em;
                        height: 0.8em;
                    }
                }

                td.feedbackFadeOut {
                    opacity: 0;

                    transition: opacity 3s ease-in;
                    -webkit-transition: opacity 3s ease-in;
                    -moz-transition: opacity 3s ease-in;
                    -ms-transition: opacity 3s ease-in;
                    -o-transition: opacity 3s ease-in;
                }
            }
        }

        .fieldSmallFont {
            font-size: 80%;
            color: var(--ListView-FieldFont);

            td.content {
                padding-bottom: 1px !important;
            }
        }

        .fieldSmallFontMarged {
            font-size: 80%;
            color: var(--ListView-FieldFont);
            margin-left: 35px;

            td.content {
                padding-bottom: 1px !important;
            }
        }

        .fieldBold {
            font-weight: bold;
        }

        .fieldUpperCaseFont {
            text-transform: uppercase;
        }

        .fieldIndention {
            padding-left: 1em !important;
        }

        .fieldMinWidth {
            width: 1%;
            white-space: nowrap;
        }
    }

    .firstListView {
        padding-top: 0.25em;
    }
