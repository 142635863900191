.progressbar {

    width: 138px;
    position: relative;
    color: black;
    font-weight: bold;
    border-color: #9D476C;
    border-style: solid;
    border-width: 1px;
    line-height: 9px;
    text-align: center;
    border-radius: 1px;
    margin-top: 2px;
    min-height: 12px;
    padding: 0 1px 0 1px;

    .percent {
        position: absolute;
        left: 20%;
        margin: 2px;
    }

    .bar {
        float: left;
        margin: 1px 0px;
        height: 10px;
        background-color: #9D476C;            
    }
}