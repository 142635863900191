

/** TravelDaysView */
.travelDaysView {
	
    table.daysMealsMatrix {

        table-layout: fixed;
        width: 100%;

        td {
            padding: 0.3em;
        }

        /** Kopfzeile (Frühstück, Mittag, Abendbrot) */
        tr.header td {
            text-transform: uppercase;
            font-variant: small-caps;
            color: #7D8694;
            text-align: center;
            font-size: 0.9em;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        /* Zeile zum Markieren aller Tage */
        tr.selectall td {
            font-style: oblique;
            white-space: nowrap;
        }

        /** Wochentag */
        td.datename {
            width: 1%;
            font-weight: bold;
        }

        /** Datum */
        td.date {
            font-weight: bold;
        }
        
        td.sunday {
            color: red;
        }

        td.checkbox {
            text-align: center;
        }

        input[type='checkbox'] {
            width: 1.5em;
            height: 1.5em;
        }
    }
}	
