.hidden {
  visibility: hidden;
}

.noContacts {
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  color: var(--page-secondary-color);
  padding-top: 15%;
  font-size: 23px;
}
