

table.voucherPDFRow {

    width: 100% !important;
    margin-bottom: 0.25em;
    table-layout: fixed;

    .leftContent {

        padding: 0.25em !important;
        white-space: nowrap !important;
        overflow: hidden;
        text-overflow: ellipsis;

        img {
            padding-right: 0.5em;
        }
    }
}

.defaultListView.voucherPhotoList {
    padding-top: 0;
}

body.voucherImage {
    overflow-x: auto;

    .imagefile {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }

    .imagefile:focus,
    .imagefile:active {
        object-fit: none;
        width: auto;
        height: auto;
        border: 0px solid transparent;
        outline: 0px solid transparent;
    }

    a {
        text-decoration: none !important;
        color: black;
    }
}

/* Detailseite mit Beleganhang: PDF mit Icon und Name */
table.voucherPDFDetail {

    padding-top: 30px;
    font-size: 20px;
    table-layout: fixed;

    td {
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
    }

    img {
        width: 150px;
        height: 150px;
    }
}

#addAnotherVoucher {
    padding-right: 17px;
}
