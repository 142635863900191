
#messageContainer {
    position: fixed;
    /*bottom: 60+100px;*/
    width: 100%;
    z-index: 100;
    bottom: 140px;

    .message {
        background-color: rgb(160, 142, 142);
        /*width: 100%;*/
        border-radius: 5px;
        padding: 0.25em 0.5em 0.25em 0.5em;
        margin: 1px 3px;
        height: auto;
        word-break: break-word;
        margin-top: 2px;
        .messageCloseWrapper {
            position: relative;
            .close {
                position: absolute;
                right: 0px;
                padding-right: 0px;
                padding-left: 8px;
                background-image: url(../images/icons/close_white.svg);
                background-position: center center;
                background-repeat: no-repeat;
                width: 15px; 
                height: 100%;
            }
        }
        

    }



    .message.error{
        background-color: #f87373;
        color: white;
    }

    .message.warning{
        background-color: #ECA926;
        color: white;
    }

    .message.affirmation{
        background-color: #98A95C;
        color: white;
    }

    .message.info{
        background-color: #0010FF;
        color: white;
    }

    .message.sync{
        background-color: #F5A623;
        color: white;
        .messageCloseWrapper {
            .close {
                background-image: url(../images/icons/close_white.svg); 
            }
        }
    }
}
