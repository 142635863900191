

.textLabelNoContent {
    margin: auto;
    text-align: center;
    color: var(--page-secondary-color);
    margin-top: 1em !important;
    margin-bottom: 7em; /* weiter runtergescrollbar, sonst verdeckt Floating Button den Label */
    font-size: 1.3em;
    padding: 0 0.9em;
    width: 100%;
    box-sizing: border-box;
}

.textLabelWithMarginTop {
    margin-top: 5em;
}
