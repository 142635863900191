
svg.timeRecordingTacho {
    margin-top: 15px;
}

.timeRecordingTachoTitle {
    color: var(--timeRecording_base_color);
    font-size: 22px;
}


.noBookings {
    color: var(--page-secondary-color);
    height: 100px;
    padding-top: 15px;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 1.1em;
}
