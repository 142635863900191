.typeAheadSearch {

    box-sizing: border-box;
	font-size: 110%;
    background-color: var(--defaultListView-Background);
        
    .search {
        padding: 0.5em;
        position: sticky;
        top: 0;
        z-index: 9;
        width: calc(100% - 1em);
        background-color: var(--FooterToolbar-Background);
    }

    input.searchfield {
        box-sizing: border-box;
        width: 100%;
        display: block;
        border: none;
        border-bottom: 1px solid #32A0E6; /* #C3C5C9; */
        padding: 5px 5px 5px 31px;
        font-family: Roboto,"Segoe UI","Frutiger",Helvetica,"Helvetica Neue",Tahoma,Verdana,Arial,sans-serif;
        font-size: 90%;
        background-image: url(../images/icons/search_icon.svg);
        background-repeat: no-repeat;
        background-position: left 5px top 8px;
        background-size: 16px;
    }
    
    td.content {
        white-space: normal !important;
        word-wrap: break-word;
        overflow-wrap: break-word;
        word-break: normal;
        -webkit-hyphens: auto;
        hyphens: auto;          
    }

    strong {
        font-weight: normal;
        border-bottom: #32A0E6 2px solid;
    }
}
