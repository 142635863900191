
.calendarControll {

    
    .calendarHeadDate {
        text-align: center;
        font-size: 16px;
        padding-top: 10px;
        padding-bottom: 10px;
        color: var(--timeRecording_base_color);
       // cursor: pointer;
        white-space: nowrap;
    }

    .calendarArrowRight {
        background: url("../images/buttons/chevron-right-blue@1x.svg");
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
    }

    .calendarArrowLeft:active {
        background-color: #67bcec3b;
    }

    .calendarArrowRight:active {
        background-color: #67bcec3b;
    }

    .calendarArrowLeft {
        background: url("../images/buttons/chevron-left-blue@1x.svg");
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
    }

    table {
        width: 100%;
        border-spacing:0; /* Removes the cell spacing via CSS */
        border-collapse: collapse;

        th {
            color: var(--page-secondary-color);
            font-weight: 400;
            width: 14%;
        }

        .dayOfMonthCell {
            text-align: center;
            border: 1px solid var(--border-color);
            color: var(--page-secondary-color);
            min-height: 20px;
            padding: 0px;
            cursor: pointer;

            .timeBar {
                height: 5px;
                font-size: 0px;
                margin-top: 4px;

            }
        }
        .dayOfMonthCell:last-child {
            border-right-width: 0;
        }

        .dayOfMonthCell:first-child {
            border-left-width: 0;
        }

        .dayOfMonthCell:active {
            background-color: #67bcec3b;
        }

        .weekend {
            background: repeating-linear-gradient(
                    145deg,
                    transparent,
                    transparent 11px,
                    var(--border-color) 10px,
                    var(--border-color) 15px
            );
        }

        .dayOfMonthCell.monthAfter {
            opacity: 0.4;
        }

        .dayOfMonthCell.monthBefore {
            opacity: 0.4;
        }

        .dayOfMonthCell.today {
            background-color: var(--Calendar-TodayBackground);
            font-weight: 600;
            color: #3E3F3F;
        }

   tr:last-child td {
       border-bottom-width: 0;
   }

    }

    table.monthChoser {

        .monthCell {
            width:25%;
            text-align: center;
            border: 1px solid #D3DFDD;
        }
    }

    table.yearChoser {
        .yearCell {
            width:20%;
            text-align: center;
            border: 1px solid #D3DFDD;
        }
    }

}
