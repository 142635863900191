.boardElementWrapper:has(.placeholder) {
  height: 444px !important;
}

.placeholder {
  font-weight: 400;
  color: var(--form-color);
  font-size: 5vw;
  justify-content: center;
  text-align: center;
}

.placeholderElement {
  height: 110% !important;
}

@media (min-width: 450px) and (max-width: 754px) {
  .placeholder {
    font-size: 3vw;
  }
}

@media (min-width: 755px) and (max-width: 879px) {
  .placeholder {
    font-size: 2.5vw;
  }
}

@media (min-width: 880px) and (max-width: 1359px) {
  .placeholder {
    font-size: 1.5vw;
  }
}

@media (min-width: 1360px) {
  .placeholder {
    font-size: 1vw;
  }
}

.boardIcon {
  margin-bottom: 20px;
  margin-top: 10px;
  width: 67px;
  height: 67px;

}

.disabled {
  visibility: hidden;
}

#invader {
  display: block;
  width: 130px;
  margin: 3em auto;
  animation: move 2s ease-in-out 0s infinite alternate;
}

#invader path {
  stroke-width: 0;
  fill: var(--form-color);
  animation: flash 1s linear 0s infinite alternate;
}
/**
@keyframes move {
  0% { transform: translate(-300px); }
  50% { transform: scale(1.2); }
  100% { transform: translateX(300px); }
}*/