

.progress-meter {

    .outer_background {
        fill: #DFEAFD;
    }
  
    .outer_foreground {
        fill: #2E7AF9; 
    }
  
    .inner_background {
        fill: #DFEAFD;
        color: var(--timeRecording_base_color)
    }
  
    .inner_foreground {
        fill: #a8263b;
    }
      
    text {
        font-family: Roboto,"Segoe UI","Frutiger",Helvetica,"Helvetica Neue",Tahoma,Verdana,Arial,sans-serif;
        font-size: 1.25em;
        font-weight: bold;
        fill: var(--page-color) !important;
    }
}
