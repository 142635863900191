.logoutFlexBox {
    .listview {
        margin: 3.2em 0.55em;
        background: none;
    }

    .loginBanner {
        margin-bottom: 30px;
        margin-top: 40px;
        display: inline-block;
        font-size: 19px;

        .projektron {
            padding-left: 5px;
            text-transform: uppercase;
            color: var(--loginTitelColor);
        }

        .bcs {
            padding-left: 5px;
            font-weight: 700;
            color: var(--loginTitelColor);
        }

        .image {
            width: 60px;
            height: 60px;
            border: 0;
            border: none;
            outline: none;
            display: inline-block;
            vertical-align: -8px;
        }

        span.beta {
            font-size: 14px;
            margin-top: 35px;
            margin-left: 5px;
            position: absolute;
            color: #7b3232;
            font-weight: 600;
        }
    }

    .clearSiteDataRow {
        margin-bottom: 20px;

        input {
            color: var(--loginFieldColor);
            background-color: var(--form-background-color) !important;
            border: none;
            border-bottom: 1px solid var(--form-border-color);
            padding-left: 5px;
            /*+5px Weil wir den InputFeldern jeweils ein Padding von 5px gegeben haben, dies macht diese 5px Breiter, daher gleichen wir dies hier aus.*/
            /* width: calc(~"100% - 5px"); */
        }

        input:active,
        input:focus {
            background-color: white;
            border: none;
            border-bottom: 2px solid #32a0e6;
        }
    }

    .formbutton {
        color: white !important;
        border: none;
        font-size: 16px;
        font-family: "Segoe UI", "Frutiger", Helvetica, "Helvetica Neue", Tahoma, Verdana, Arial,
            sans-serif;
        min-width: 100px;
        background-color: var(--form-highlight-color) !important;
        padding-bottom: 5px;
        height: 35px;
        border-radius: 6px !important;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        font-weight: normal;
        width: 100%;
    }

    .cancelLogout {
        background-color: var(--form-border-color) !important;
    }

    .formcheckbox {
        /* Checkbox auch mittig anordnen */
        margin-left: 6px;
    }
    #clearSiteData {
        margin-top: -1px;
    }

    .unsynchedChangesField {
        /* Warninhinweis rot machen */
        color: var(--TimeRecording_AlertColor);
    }

    .authSeparator {
        flex-grow: 1;
        display: block;
        margin-top: 40px;
        color: var(--border-color);
    }
}
