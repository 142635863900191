/**
 * Spesen-Boardelement:
 *   - Neue Dienstreise / Aktuelle Dienstreise - Link zum Erstellen einer neuen / Öffnen der aktuellen Dienstreise
 *   - Anzahl Dienstreise / Einzelbelege - Link zu Spesenliste
 *
 * Anzeige "Spesenerfassung nicht verfügbar", wenn Spesenerfassung nicht lizensiert / deaktiviert
 */
.allowanceSummary {

    font-size: 1.1em;

    box-sizing: border-box;
    color: var(--allowance_base_color);
    width: 100%;
    height: 100%;
    padding: 3px;
    
    /*
    table.summary {

        width: 100%;

        td.summaryCount {
            padding: 0 0.5em 0 0;
        }

        td.summaryLabel {
            padding: 0;
        }
    }
    */

    .summaryTable {
        
        .countTravels {
           vertical-align: top;
           padding-left: 0.5em;
        }
    }

    /* Fortschrittsbalken der aktuellen Dienstreise */
    .allowanceprogressbar {
    
        .progressbar {
            border-color: #9D476C;
        }

        .bar {
            background-color: #9D476C;            
        }
    }

    /* Spesenerfassung nicht verfügbar */
    .allowanceRecordingDisabled {
        color: #E6EAF0;
    }
    
    .countVouchers {
        vertical-align: top;
    }
    .newTravel {
        text-align: center;
        display: table !important;
    }

    .disabled{
        visibility: hidden;
    }
}
