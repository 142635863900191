    #submenu {
        position: fixed;
        background: var(--subMenuBackground);
        z-index: 15;
        /*border: 1px solid gray;*/

        -webkit-box-shadow: 0px 0px 52px -7px rgba(0, 0, 0, 0.35);
        -moz-box-shadow: 0px 0px 52px -7px rgba(0, 0, 0, 0.35);
        box-shadow: 0px 0px 52px -7px rgba(0, 0, 0, 0.35);
        border-radius: 5px;

        font-size: 24px;
        white-space: nowrap;

        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
        /* Non-prefixed version, currently
                                         supported by Chrome and Opera */

        margin-top: 20px;

        tr td {
            padding: 10px 30px 10px 30px;
            text-align: center;
            color: var(--page-color);
            border-bottom: 1px solid var(--subMenu-Border);
        }

        tr:last-child td {
            padding: 10px 30px 10px 30px;
            text-align: center;
            border-bottom: none;
        }


        tr:hover {
            background-color:var(--subMenuBackground);
        }
    }
