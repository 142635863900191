.contactBox {
  font-weight: 500;
  color: var(--contactBoxColor);
  font-size: 1.1em;
  justify-content: center;
  height: 100%;

  div{
    justify-content: center;
  }
}

.boardIcon {
  margin-bottom: -10px;
  width: 27px;
  height: 27px;

}

.disabled {
  visibility: hidden;
}
