table.tableview {

    width: 100%;

    tr.header {
        background-color: lightgrey;
    }

    td.Label {
        background-color: lightgrey;
    }

    td {
        padding: 0 0.5em 0 0;
    }

    td.alignRight {
        text-align: right;
    }
}
